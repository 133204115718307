$blue: rgb(7, 92, 151);

@media(min-width: 1600px) {
	.container {
		max-width: 1470px;
	}
}

body {
	font-family: 'Yandex Sans Text', SansSerif, Arial;
	font-weight: 300;
	font-size: 20px;
}

img {
	max-width: 100%;
}

.h1 {
	font-size: 82px;
	font-weight: bold;
	line-height: 1.183;
	margin-bottom: 65px;
	@media(max-width: 1599px) {
		font-size: 60px;
	}
}

.h2 {
	font-size: 48px;
	color: $blue;
	font-weight: normal;
	@media(max-width: 1199px) {
		font-size: 40px;
	}
	@media(max-width: 767px) {
		font-size: 32px;
	}
}

.blue-bg {
	position: absolute !important;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #465969;
	opacity: .8;
	z-index: 0;
}

/* text-based popup styling */
.white-popup {
	position: relative;
	width: auto;
	max-width: 600px;
	margin: 0 auto;
	background: rgba(251, 251, 253, .9);
	border-radius: 0 80px 0 80px;
	padding: 70px;
	text-align: center;
	font-weight: normal;

	&.calc-box {
		max-width: 800px;
	}

	.mfp-close {
		left: 15px;
		top: 15px;
		color: $blue;
		font-size: 80px;
		font-family: "Yandex Sans Text";
		font-weight: 100;
		opacity: 1;
	}

	i {
		display: block;
		text-align: center;
		font-size: 80px;
		color: $blue;
		margin-bottom: 30px;
	}

	p {
		margin-bottom: 0;
	}
}

/*

====== Zoom effect ======

*/
.mfp-zoom-in {

	/* start state */
	.mfp-with-anim {
		opacity: 0;
		transition: all 0.2s ease-in-out;
		transform: scale(0.8);
	}

	&.mfp-bg {
		opacity: 0;
		transition: all 0.3s ease-out;
	}

	/* animate in */
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			transform: scale(1);
		}

		&.mfp-bg {
			opacity: 0.8;
		}
	}

	/* animate out */
	&.mfp-removing {

		.mfp-with-anim {
			transform: scale(0.8);
			opacity: 0;
		}

		&.mfp-bg {
			opacity: 0;
		}

	}

}

.calc-box {
	background: rgba(251, 251, 253, 0.9);
	border-radius: 0 80px 0 80px;
	padding: 70px 160px;
	text-align: center;
	@media(max-width: 991px) {
		padding: 70px 80px;
	}
	@media(max-width: 767px) {
		padding: 30px;
	}

	.h2 {
		margin-bottom: 75px;
		line-height: 1;
		@media(max-width: 767px) {
			font-size: 26px;
			margin-bottom: 30px;
		}
	}

	p {
		color: #000000;
	}

	p.title {
		color: #000;
		font-weight: normal;
		line-height: 1;
		margin-bottom: 35px;
	}

	form {
		.box {
			display: none;

			&:nth-child(1) {
				display: block;
			}
		}

		.suggestions-suggestions {
			//width: 100% !important;
			margin: auto !important;
			text-align: left !important;
			padding-left: 10px;
			font-size: 16px;

			a {
				display: none;
			}
		}

		input {
			width: 337px;
			height: 72px;
			box-shadow: 0px 7px 8.55px 0.45px rgba(49, 49, 54, 0.18);
			border: none;
			display: block;
			margin: 0 auto 25px;
			outline: none;
			padding-left: 15px;
			@media(max-width: 767px) {
				width: 100%;
			}
		}

		.btn {
			background: $blue;
			width: 337px;
			height: 72px;
			color: #ffffff;
			border-radius: 0;
			font-size: 16px;
			font-weight: bold;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto 45px auto;
			transition: all .3s;
			opacity: .7;

			&:hover {
				opacity: 1;
			}

			@media(max-width: 767px) {
				width: auto;
			}
		}
	}
}

body.page {
	header {
		background: url("/img/bg-mob.jpg") center no-repeat;
		background-size: cover;
		video {
			display: none !important;
		}
	}
}

header {
	background-size: cover;
	position: relative;
	height: 991px;
	overflow: hidden;
	@media(max-width: 1599px) {
		height: 830px;
	}
	@media(max-width: 991px) {
		height: 600px;
		background: url("/img/bg-mob.jpg") center no-repeat;
		background-size: cover;
	}

	video {
		position: absolute;
		width: 1920px;
		height: auto;
		z-index: 0;
	}


	.top {
		@media(max-width: 991px) {
			position: fixed;
			width: 100%;
			top: 0;
			left: 0;
			padding: 15px 0;
			background: rgba(7, 92, 151, 0.5);
			z-index: 999;
			transition: background-color .3s;
			&.active {
				background: rgba(7, 92, 151, 0.9);
			}
		}
	}

	nav {
		.bord {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 6px;
			background: #ffffff;
			transition: all .3s;
			@media(max-width: 991px) {
				display: none;
			}
		}

		@media(max-width: 991px) {
			display: none;
		}
	}

	.mob-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media(max-width: 991px) {

		}

		.logo {
			padding-top: 30px;
			display: block;
			width: 100px;
			@media(max-width: 991px) {
				padding-top: 0;
			}
		}

		.nav-btn {
			position: relative;
			height: 22px;
			display: none;
			@media(max-width: 991px) {
				display: block;
			}

			.hr {
				position: absolute;
				right: 0;
				width: 35px;
				height: 3px;
				background: #fff;
				transition: all .3s;

				&:nth-child(1) {
					top: 0;
				}

				&:nth-child(2) {
					top: 50%;
					transform: translateY(-50%);
				}

				&:nth-child(3) {
					bottom: 0;
				}
			}

			&.active {
				.hr {
					&:nth-child(1) {
						top: 50%;
						bottom: auto;
						transform: translateY(-50%) rotate(225deg);
					}

					&:nth-child(2), &:nth-child(3) {
						top: 50%;
						bottom: auto;
						transform: translateY(-50%) rotate(-225deg);
					}
				}
			}
		}
	}

	ul.nav {
		justify-content: flex-end;
		@media(max-width: 991px) {
			display: block;
			margin-top: 30px;
			margin-bottom: 30px;
		}

		li {

			a {
				padding-left: 40px;
				padding-bottom: 20px;
				padding-top: 40px;
				color: rgba(255, 255, 255, 0.71);
				text-transform: uppercase;
				font-size: 16px;
				line-height: 1;
				display: block;
				text-decoration: none;
				transition: all .3s;
				@media(max-width: 991px) {
					font-weight: bold;
				}
			}

			&:hover, &.active {
				a {
					color: #ffffff;
				}
			}

			@media(max-width: 991px) {
				margin-left: 0;
			}
		}
	}

	.social {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 18px;
		@media(max-width: 991px) {
			justify-content: center;
		}

		a {
			color: rgba(255, 255, 255, 0.7);
			margin-left: 20px;
			font-size: 25px;
			transition: all .3s;

			&:hover {
				color: #ffffff;
			}

			@media(max-width: 991px) {
				margin: 0 10px;
			}
		}
	}

	.title-block {
		color: #ffffff;
		@media(max-width: 991px) {
			text-align: center;
		}

		.h1 {
			margin-top: 95px;
			@media(max-width: 991px) {
				font-size: 50px;
				text-align: center;
			}
			@media(max-width: 767px) {
				font-size: 32px;
				margin-top: 135px;
			}
		}
	}

	.scene-box {
		@media(max-width: 991px) {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.scene {
		position: absolute;
		display: block;
		z-index: 2;
		pointer-events: auto !important;
		@media(max-width: 991px) {
			position: relative;
			top: 0 !important;
			right: 0 !important;
			width: 100px !important;
			height: 100px !important;
			margin: 30px 15px;
			img {
				position: absolute;
				top: 0;
				left: 0;

				&:nth-child(2) {
					margin-left: 10px !important;
					margin-top: 10px !important;
				}
			}
		}

		img {
			&:nth-child(1) {
				z-index: 2;
			}

			&:nth-child(2) {
				margin-left: 25px;
				margin-top: 25px;
				z-index: 1;
			}
		}
	}


	#parahod {
		width: 159px;
		height: 159px;
		right: 45px;
		top: 70px;
		@media(max-width: 1599px) {
			width: 100px;
			height: 100px;
		}
	}

	#avia {
		width: 256px;
		height: 256px;
		top: 235px;
		right: 250px;
		@media(max-width: 1599px) {
			width: 170px;
			height: 170px;
			top: 200px;
			right: 175px;
		}
	}

	#vagon {
		width: 222px;
		height: 222px;
		top: 530px;
		right: 50px;
		@media(max-width: 1599px) {
			width: 148px;
			height: 148px;
			top: 420px;
			right: 50px;
		}
	}

	#kamaz {
		width: 213px;
		height: 213px;
		top: 560px;
		right: 463px;
		@media(max-width: 1599px) {
			width: 142px;
			height: 142px;
			top: 475px;
			right: 300px;
		}
	}
}

section.our-services {
	overflow: hidden;
	padding-top: 75px;
	@media(max-width: 767px) {
		padding-bottom: 40px;
		padding-top: 30px;
	}

	.h2 {
		margin-bottom: 50px;
		line-height: 1;
		@media(max-width: 449px) {
			margin-bottom: 30px;
		}
	}

	p {
		line-height: 1.8;
	}

	.owl-services {
		width: 100vw;
		margin-top: 70px;
		@media(max-width: 449px) {
			margin-top: 30px;
			width: auto;
		}

		.owl-stage {
			transition-timing-function: linear !important;
		}

		.item {
			width: 240px;
			transition: opacity .3s;
			display: block;
			color: #000000;

			&:hover {
				opacity: .8;
				text-decoration: none;
			}

			@media(max-width: 449px) {
				width: 260px;
			}

			p {
				font-size: 18px;
				font-weight: 300;
				margin-bottom: 0;

				&.title {
					font-size: 22px;
					font-weight: bold;
					margin-top: 45px;
					margin-bottom: 20px;
					line-height: 1;
				}
			}
		}

		.owl-dots {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 120px 0 0 0;
			padding: 0;
			@media(max-width: 767px) {
				margin: 40px 0 0 0;
			}

			.owl-dot {
				flex: 1 1 0;
				width: 0;
				height: 6px;
				background: #d4d4d4;
				outline: none;

				&.active {
					background: #313941;
					outline: none;
				}
			}
		}
	}
}

section.delivery {
	background: url("/img/delivery-bg.jpg") center no-repeat;
	background-size: cover;
	padding-top: 75px;
	padding-bottom: 150px;
	position: relative;
	@media(max-width: 767px) {
		padding-top: 30px;
	}

	* {
		position: relative;
	}

	.h2 {
		text-align: center;
		color: #ffffff;
	}

	#delivery-scene {
		width: 1160px;
		height: 500px;
		margin: 120px auto 0 auto;
		font-size: 38px;
		color: #ffffff;
		text-transform: uppercase;
		pointer-events: auto !important;
		@media(max-width: 1199px) {
			width: 100%;
			font-size: 30px;
		}
		@media(max-width: 991px) {
			font-size: 18px;
		}
		@media(max-width: 767px) {
			font-size: 14px;
			margin: 0 auto;
			height: 195px;
			max-width: 380px;
		}

		div {
			cursor: pointer;
			z-index: 99;
			@media(max-width: 767px) {
				position: absolute;
				top: 10px;
				left: 15px;
			}

			span {
				display: inline-block;
				opacity: .8;
				transition: all .3s;
			}

			&:hover {
				span {
					opacity: 1;
					transform: scale(1.2);
				}
			}

			&:nth-child(1) {
				margin-left: 24.56%;
			}

			&:nth-child(2) {
				margin-top: 15px;
				margin-left: 48.19%;
			}

			&:nth-child(3) {
				margin-left: 5.6%;
				margin-top: 66px;
			}

			&:nth-child(4) {
				margin-top: 85px;
				margin-left: 35.86%;
			}

			&:nth-child(5) {
				margin-top: 95px;
				margin-left: 65.51%;
			}

			&:nth-child(6) {
				margin-top: 132px;
				margin-left: 20.26%;
			}

			&:nth-child(7) {
				margin-top: 134px;
				margin-left: 49.14%;
			}

			&:nth-child(8) {
				margin-top: 175px;
				margin-left: 73.7%;
			}

			&:nth-child(9) {
				margin-top: 233px;
				margin-left: 0;
			}

			&:nth-child(10) {
				margin-top: 212px;
				margin-left: 31.72%;
			}

			&:nth-child(11) {
				margin-top: 232px;
				margin-left: 58.19%;
			}

			&:nth-child(12) {
				margin-top: 255px;
				margin-left: 71.81%;
			}

			&:nth-child(13) {
				margin-top: 334px;
				margin-left: 15.51%;
			}

			&:nth-child(14) {
				margin-top: 332px;
				margin-left: 33.7%;
			}

			&:nth-child(15) {
				margin-top: 330px;
				margin-left: 57.33%;
			}

			&:nth-child(16) {
				margin-top: 333px;
				margin-left: 81.9%;
			}

			&:nth-child(17) {
				margin-top: 408px;
				margin-left: 5.6%;
			}

			&:nth-child(18) {
				margin-top: 446px;
				margin-left: 42.07%;
			}

			&:nth-child(19) {
				margin-top: 411px;
				margin-left: 61.64%;
			}
		}
	}
}

section.calc {
	background: url("/img/calc-bg.jpg") center no-repeat;
	background-size: cover;
	padding-top: 140px;
	padding-bottom: 156px;
	position: relative;

	.cont {
		position: absolute;
		right: 0;
		top: 0;
		transform: translateY(-50%);
		@media(max-width: 1599px) {
			width: 200px;
		}
		@media(max-width: 767px) {
			width: 120px;
		}
	}

	.poezd {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 257px;
		@media(max-width: 1599px) {
			width: 320px;
		}
		@media(max-width: 1199px) {
			width: 250px;
		}
		@media(max-width: 991px) {
			top: auto;
			bottom: 0;
			transform: translateY(50%);
			z-index: 2;
		}
	}
}

section.customs-clearance {
	background: url("/img/customs-clearance-bg.jpg") center no-repeat;
	background-size: cover;
	position: relative;
	color: #ffffff;
	padding-top: 75px;
	padding-bottom: 30px;

	* {
		position: relative;
		z-index: 1;
	}

	p.title {
		font-size: 36px;
		font-weight: normal;
		text-align: center;
		text-transform: uppercase;
		width: 900px;
		max-width: 100%;
		margin: 0 auto 75px;
		@media(max-width: 991px) {
			margin: 30px auto 50px;
		}
		@media(max-width: 767px) {
			font-size: 24px;
		}
	}

	ul {
		margin: 0;
		padding: 0 0 0 20px;
		list-style-type: none;

		li {
			font-size: 18px;
			margin-top: 20px;
			list-style-image: url("/img/arrow.png");
		}
	}

	img {
		display: block;
		@media(max-width: 991px) {
			margin: 30px auto 0;
		}
	}
}

section.preparation-documents {
	position: relative;
	color: #000000;
	padding-top: 115px;
	padding-bottom: 175px;
	@media(max-width: 767px) {
		padding-bottom: 30px;
	}

	.aircraft {
		position: absolute;
		left: 0;
		top: 0;
		transform: translateY(-40%);
		z-index: 1;
		@media(max-width: 1599px) {
			width: 200px;
		}
	}

	.h2 {
		margin-bottom: 35px;
		@media(max-width: 991px) {
			text-align: center;
		}
	}

	p {
		font-size: 24px;
		line-height: 1.2;
		@media(max-width: 991px) {
			text-align: center;
		}

		strong {
			font-size: 18px;
			font-weight: bold;
			display: inline-block;
			margin-top: 30px;
		}
	}

	form {
		font-size: 18px;
		color: $blue;
		@media(max-width: 767px) {
			text-align: center;
		}

		input[type="email"] {
			display: block;
			width: 229px;
			height: 42px;
			border: 1px solid $blue;
			padding-left: 13px;
			margin-bottom: 20px;
			color: $blue;
			@media(max-width: 767px) {
				margin: 0 auto 20px;
			}

			&::placeholder {
				color: rgba(7, 92, 151, 0.59);
			}
		}

		.file-upload input[type="file"] {
			display: none;
			/* скрываем input file */
		}

		.file-upload {
			color: $blue;
			text-decoration: underline;
			@media(max-width: 767px) {
				text-align: center;
			}

			label {
				display: inline-block;
				cursor: pointer;
			}

			span {
				font-weight: bold;
				text-decoration: underline;
			}
		}

		button {
			width: 229px;
			height: 42px;
			background: $blue;
			color: #ffffff;
			text-transform: uppercase;
			font-size: 16px;
			border: none;
			cursor: pointer;
			opacity: .7;
			transition: all .3s;
			line-height: 1;
			margin-top: 25px;

			&:hover {
				opacity: 1;
			}
		}
	}

	.documents {
		float: right;
		@media(max-width: 991px) {
			float: none;
			text-align: center;
			margin-top: 50px;
		}
	}
}

section.control {
	background: url("/img/control.jpg") center no-repeat;
	background-size: cover;
	padding-top: 70px;
	@media(max-width: 991px) {
		text-align: center;
	}
	@media(max-width: 767px) {
		padding-top: 30px;
	}

	.phone {
		display: none;
		background: url("/img/anim9.png") center no-repeat;
		background-size: cover;
		width: 290px;
		height: 490px;
		padding: 71px 19px 0 31px;
		@media(max-width: 767px) {
			margin: auto;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}

	p {
		width: 410px;
		max-width: 100%;
		font-size: 18px;
		font-weight: normal;
		font-family: "Yandex Sans Display";
		margin-bottom: 33px;
		@media(max-width: 991px) {
			text-align: center;
			width: 100%;
		}
		@media(max-width: 767px) {
			margin-bottom: 20px;
		}
	}

	p.title {
		width: 100%;
		font-size: 47px;
		line-height: 1.2;
		font-weight: normal;
		margin-top: 60px;
		margin-bottom: 50px;
		@media(max-width: 1199px) {
			font-size: 40px;
		}
		@media(max-width: 767px) {
			margin-top: 0;
			margin-bottom: 30px;
		}
	}
}

section.maps {
	padding-top: 100px;
	@media(max-width: 767px) {
		padding-top: 30px;
	}

	.h2 {
		text-align: center;
		margin-bottom: 30px;
	}

	.map {
		position: relative;

		ymaps.ymaps-2-1-73-zoom {
			display: none !important;
		}

		.white-bg {
			position: absolute;
			width: 100%;
			top: 0;
			z-index: 1;
		}

		.contacts {
			position: absolute;
			width: 100%;
			height: 0;
			top: 50%;
			z-index: 1;

			.container {
				height: 0;
			}

			.box {
				background: rgba(255, 255, 255, 0.9);
				width: 387px;
				padding: 30px 100px 30px 30px;
				color: #000000;
				font-size: 16px;
				font-weight: normal;
				transform: translateY(-50%);
				@media(max-width: 767px) {
					width: auto;
					padding: 30px;
				}

				p {
					margin-bottom: 5px;
				}

				a {
					display: block;
					color: #000000;
					text-decoration: underline;

					&.phone {
						font-size: 30px;
						font-weight: normal;
						text-decoration: none;
						margin-top: 40px;
					}
				}
			}
		}
	}
}

footer {
	background: #212f3b;
	padding: 60px 0;
	font-size: 18px;
	@media(max-width: 767px) {
		padding: 0;
	}

	.logo {
		@media(max-width: 991px) {
			text-align: center;
			display: block;
			width: 100%;
			margin-bottom: 50px;
		}
		@media(max-width: 767px) {
			display: none;
		}
	}

	nav {
		display: flex;
		justify-content: flex-end;
		@media(max-width: 991px) {
			justify-content: space-between;
		}
		@media(max-width: 767px) {
			display: block;
		}

		ul {
			margin: 0 0 0 100px;
			padding: 0;
			list-style-type: none;
			@media(max-width: 1599px) {
				margin: 0 0 0 80px;
			}
			@media(max-width: 991px) {
				margin: 0 30px;
			}
			@media(max-width: 767px) {
				margin: 50px 0;
			}

			li {
				margin-bottom: 20px;
				line-height: 1;
				@media(max-width: 767px) {
					margin-bottom: 10px;
				}

				a {
					color: #ffffff;
					font-weight: normal;

					&:hover {
						text-decoration: underline;
						color: #ffffff;
					}
				}
			}
		}
	}

	hr {
		border-top: 1px solid rgba(255, 255, 255, 0.33);
		margin: 50px 0;
		@media(max-width: 767px) {
			margin: 0 0 50px;
		}
	}

	.bottom {
		display: flex;
		justify-content: space-between;
		color: #ffffff;
		font-weight: normal;
		@media(max-width: 767px) {
			display: block;
			text-align: center;
			padding-bottom: 50px;
		}

		a {
			color: #ffffff;
		}
	}
}

.breadcrumb {
	background: none;
	font-size: 14px;
	margin-top: 30px;
}

section.content {
	margin-bottom: 100px;
	font-size: 16px;
}

